<p-table [value]="allAssets" [lazy]="true" (onLazyLoad)="loadItems($event)"
         styleClass="p-datatable-striped p-datatable-gridlines p-datatable-sm"
         (sortFunction)="customSort($event)" [customSort]="true" sortField="{{defaultSortFieldName}}"
         [sortOrder]="sortingOrder"
         [selectionMode]="selectionMode" [(selection)]="modernSelectedRows"
         (selectionChange)="modernSelectedRowsChange($event)"
         [selectionPageOnly]="true" [stateKey]="sessionStorageName" dataKey="_id" stateStorage="session"
         [scrollable]="true" scrollHeight="flex" class="prime-table-container">

  <ng-template pTemplate="caption">


    @if (!isMobileView) {
      <div class="">
        <div class="flex flex-row	justify-content-between header-container">
          <!-- search text -->
          @if (isTableSearchable) {
            <div class="flex flex-row" style="max-width: auto;">
              <span class="p-input-icon-left ml-auto ">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (ngModelChange)="onSearchEvent($event)" [ngModel]="searchText" placeholder="Search" />
              </span>
            </div>
          }
          <!-- filte pils -->
          <div class="flex flex-row" style="max-width: auto;">
            <!-- <p class="store-name" *ngIf="selectedStore && selectedStore['name']">{{selectedStore['name']}}</p>                     -->
            @if (showColumnSelectorDropdown) {
              <div class="">
                <p-multiSelect [(ngModel)]="seenOrderTableHeaderArray" [options]="orderTableHeaderArray"
                               optionLabel="header" dropdownIcon="pi pi-bars"
                               selectedItemsLabel="{0} columns selected"
                               [style]="{maxWidth: '100%', marginBottom: '1rem'}"
                               placeholder="Choose Columns"
                               (onChange)="refreshOrderedSeenOrderTableHeaderArray($event)" appendTo="body"
                               (overlayVisibleChange)="onDropdownToggle($event)"></p-multiSelect>
              </div>
            }
            <div class="flex flex-row">
              @for (pill of selectedFilterPillsArray; track pill) {
                <p-chip [ngClass]="{'hideFilterValues' : pill.key !== 'expectedDeliveryDateTimeDetails'}"
                        [label]="pill.label" [pTooltip]="pill.tooltip"
                        tooltipPosition="top"
                        [removable]="pill.removeAction" (onRemove)="pillRemoved(pill)"
                        styleClass="mx-2 my-1 custom-chip"></p-chip>
              }
            </div>
          </div>
          <!-- table buttons  -->
          <div style="min-width: auto" class="prime-ng-btns">
            @if (arrayOfDataViewTypePills.length > 0) {
              <span class="FR" style="margin: 2px 0">
                <app-iotzen-pills [pillActiveColour]="'#6D7FCC'" [isPillTabToggleRequired]="false"
                                  [arrayOfPills]="arrayOfDataViewTypePills"
                                  (activePillValue)="setChartType($event)"
                ></app-iotzen-pills>
              </span>
            }
            <!-- drop down buttons -->
            @if (dropdownBtnConfig) {
              <span [style]="{float: 'right'}">
                @if (dropdownBtnConfig && dropdownBtnConfig.length > 0) {
                  <p-dropdown
                    (onChange)="isStoreSelected($event)" [(ngModel)]="selectedStore"
                    [options]="dropdownBtnConfig | orderBy: 'name'" optionLabel="name" [filter]="true"
                    [styleClass]="'mr-3 mb-3 p-button-sm'+ ' ' + dropDownStyleClass"
                  ></p-dropdown>
                }
              </span>
            }
            @for (cBtn of buttonConfigObjArray; track cBtn) {
              <span class="FR flex">
                @if (cBtn.type === 'single') {
                  <button label="{{cBtn?.label | translate}}" pButton pRipple
                          [icon]="cBtn.pIcon ? cBtn.pIcon : ''" class="mr-3 mb-3 p-button-sm"
                          [ngClass]="{'p-button-danger': cBtn?.removeButton} "
                          (click)="btnClicked(cBtn); cBtn?.showPopup ? confirm($event) : '';" [disabled]="cBtn.disabled"
                          pTooltip="{{cBtn?.toolTipData | translate}}" tooltipPosition="bottom">
                  </button>
                }
                @if (cBtn.type === 'dropDown') {
                  <p-splitButton appendTo="body" [icon]="cBtn.pIcon ? cBtn.pIcon : ''"
                                 label="{{cBtn.label | translate}}" (onClick)="btnClicked(cBtn)"
                                 [model]="cBtn.dropDownBtnValues"
                                 styleClass="mr-3 mb-3 p-button-sm">
                  </p-splitButton>
                }
                @if (cBtn.type === 'minimal') {
                  <div class="minimal-btn-container">
                    <button class="cta" pButton pRipple>
                      <i class="fas fa-plus"></i>
                      <span class="button-text"></span>
                    </button>
                  </div>
                }
                @if (cBtn.type === 'select-button') {
                  <div>
                    <p-selectButton (onChange)="btnClicked($event)" [(ngModel)]="selectButtonValue"
                                    [options]="cBtn.stateOptions" [style]="{'margin-bottom': '1rem', 'width':' 10rem'}"
                                    optionLabel="{{cBtn?.label | translate}}"
                                    pRipple></p-selectButton>
                  </div>
                }
                @if (cBtn.type === 'icon-button') {
                <div>
                  <p-button (click)="op.toggle($event)"
                    [ngClass]="{'filter-btn-active': selectedActiveUnactiveFilterMode()?.length ,'filter-btn-inactive': !selectedActiveUnactiveFilterMode()?.length}"
                    class="filter-btn p-button-sm" [icon]="cBtn.pIcon ? cBtn.pIcon : ''">
                  </p-button>
                </div>
                <p-overlayPanel #op styleClass="filter-panel-container" showCloseIcon="true" [showClear]="true">
                  <ng-template pTemplate>
                    <div class="filter-container">
                      <span [style]="{float: 'right'}">
                        <p class="filter-wrapper-title">{{cBtn?.optionsLabel}}:</p>
                        <p-multiSelect [options]="cBtn?.options" [(ngModel)]="selectedActiveUnactiveFilterMode" 
                          [defaultLabel]="'Select'" (onChange)="buildSelectedFilterPillsArray(lazyLoadedObject.filters)"
                          optionLabel="label" filterPlaceHolder="Select" [filter]="true" display="chip">
                        </p-multiSelect>
                      </span>
                    </div>
                  </ng-template>
                </p-overlayPanel>
                }
              </span>
            }
            @if (showTodayPlacedOrderButton) {
              <div
                class="table-order-status-select-button"
              >
                <p-selectButton (onChange)="setShowTodaysPlacedOrder()" [(ngModel)]="orderStatus"
                                [options]="orderStatusOptions" class="order-status"
                                pRipple></p-selectButton>
              </div>
            }
            <!-- Refresh Icons For Getting New Orders -->
            <ng-container *ngTemplateOutlet="getNewOrdersOnRefreshUi"></ng-container>
            <!-- <button (click)="setShowTodaysPlacedOrder()" *ngIf="showTodayPlacedOrderButton" style="cursor: pointer; border: none; outline: none; margin-right: 0.75rem; color:white; background-color: #026FB9; padding-inline: 1rem; padding-block: 0.45rem; border-radius: 0.5rem;">
            Show Orders To be Served
          </button> -->
        </div>
      </div>
    </div>
    }

    <!-- mobile view captions  -->
    @if (isMobileView) {
      <div class="table-caption-container  ">
        @if (showColumnSelectorDropdown) {
          <div class=""
               [ngClass]="{'hide-header-content': (!tableCaptionButtonMenuClosed ? true : false), 'show-containers': tableCaptionButtonMenuClosed }">
            <div class="flex align-items-center justify-content-start flex-row mobile-view-pills-container"
                 [ngClass]="{'pills-active': showFilterPills}">
              <div class="shadow">
              </div>
              <button (click)="showFilters()" [icon]="'pi '+ (showFilterPills ?  'pi-times':  'pi-filter')"
                      class="round-button" pButton
                      pRipple type="button">
              </button>
              @if (showFilterPills) {
                <div class="flex flex-wrap show-pills"
                     [ngClass]="{'show-containers':showFilterPills, 'tag-rail mobile-chip': showFilterPills }">
                  <div class="rail-container" id="rail">
                    @for (pill of selectedFilterPillsArray; track pill; let i = $index) {
                      <p-chip [label]="pill.label" [pTooltip]="pill.tooltip"
                              tooltipPosition="top" [removable]="pill.removeAction" (onRemove)="pillRemoved(pill)"
                    styleClass="mx-2 my-1 custom-chip tag" ></p-chip>
                    }
                  </div>
                </div>
              }
              @if (!showFilterPills) {
                <p-multiSelect [options]="orderTableHeaderArray" [(ngModel)]="seenOrderTableHeaderArray"
                               optionLabel="header"
                               dropdownIcon="pi pi-bars" selectedItemsLabel="{0} columns selected"
                               [ngClass]="{'hide-header-content': (!tableCaptionButtonMenuClosed ? true : false), 'show-containers': tableCaptionButtonMenuClosed }"
                               [style]="{maxWidth: '100%', marginBottom: '1rem', position: 'relative', zIndex: 1}"
                               placeholder="Choose Columns" appendTo="body"
                               (onChange)="refreshOrderedSeenOrderTableHeaderArray($event)"></p-multiSelect>
              }
            </div>
          </div>
        }
        @if (!showFilterPills) {
          <div class="drop-down-container"
               [ngClass]="{'hide-header-content': (!tableCaptionButtonMenuClosed ? true : false), 'show-containers': tableCaptionButtonMenuClosed }">
            <!-- drop down buttons -->
            @if (dropdownBtnConfig) {
              <span>
              @if (dropdownBtnConfig && dropdownBtnConfig.length > 0) {
                <p-dropdown (onChange)="isStoreSelected($event)"
                            [(ngModel)]="selectedStore" [options]="dropdownBtnConfig | orderBy: 'name'"
                            optionLabel="name" [filter]="true"
                            [styleClass]="'p-button-sm'+ ' ' + dropDownStyleClass"></p-dropdown>
              }
            </span>
            }
          </div>
        }
        @if (!showFilterPills) {
          <div class="mobile-view-button-array"
               [ngClass]="{'hide-header-content': (tableCaptionButtonMenuClosed ? true : false) }">
            @for (cBtn of buttonConfigObjArray; track cBtn) {
              <span class="FR flex">
              @if (cBtn.type === 'single') {
                <button label="{{cBtn?.label | translate}}" pButton pRipple
                        [icon]="cBtn.pIcon ? cBtn.pIcon : ''" class="mr-3 mb-3 p-button-sm"
                        [ngClass]="{'p-button-danger': cBtn?.removeButton, 'cust-button': cBtn?.pIcon} "
                        (click)="btnClicked(cBtn); cBtn?.showPopup ? confirm($event) : '';" [disabled]="cBtn.disabled"
                        pTooltip="{{cBtn?.toolTipData | translate}}" tooltipPosition="bottom">
                </button>
              }
                @if (cBtn.type === 'dropDown') {
                  <p-splitButton [icon]="cBtn.pIcon ? cBtn.pIcon : ''"
                                 label="{{cBtn.label | translate}}" (onClick)="btnClicked(cBtn)"
                                 [model]="cBtn.dropDownBtnValues"
                                 styleClass="mr-3 mb-3 p-button-sm">
                </p-splitButton>
                }
                @if (cBtn.type === 'minimal') {
                  <div class="minimal-btn-container">
                  <button class="cta" pButton pRipple>
                    <i class="fas fa-plus"></i>
                    <span class="button-text"></span>
                  </button>
                </div>
                }
                @if (cBtn.type === 'select-button') {
                  <div>
                  <p-selectButton [options]="cBtn.stateOptions" [style]="{'margin-bottom': '1rem', 'width':' 10rem'}"
                                  pRipple
                                  [(ngModel)]="selectButtonValue" optionLabel="{{cBtn?.label | translate}}"
                                  (onChange)="btnClicked($event)"></p-selectButton>
                </div>
                }
            </span>
            }
            @if (showTodayPlacedOrderButton) {
              <div style="display: flex; align-items: center; justify-content: center; padding-right: 0.75rem;"
              >
                <p-selectButton [(ngModel)]="orderStatus" [options]="orderStatusOptions" class="order-status" pRipple
                                (onChange)="setShowTodaysPlacedOrder()"></p-selectButton>
              </div>
            }
          </div>
        }
        @if ((buttonConfigObjArray.length > 0 || showTodayPlacedOrderButton) && !showFilterPills) {
          <div class="menu-button ">
            <p-button [icon]="(tableCaptionButtonMenuClosed ? 'pi pi-bars' : 'pi pi-times')" pRipple
                      styleClass="p-button-secondary"
          (click)="tableCaptionButtonMenuClosed = !tableCaptionButtonMenuClosed"></p-button>
          </div>
        }
    </div>
    }

    <!-- <div class="align-items-center">
    <p-multiSelect [options]="orderTableHeaderArray" [(ngModel)]="seenOrderTableHeaderArray" optionLabel="header"
      selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"
    (onChange)="refreshOrderedSeenOrderTableHeaderArray($event)"></p-multiSelect>

    <p-chip *ngFor="let pill of selectedFilterPillsArray" [label]="pill.label" [pTooltip]="pill.tooltip" tooltipPosition="top"
    [removable]="pill.removeAction" (onRemove)="pillRemoved(pill)" styleClass="mx-2 my-2 custom-chip"></p-chip>
  </div> -->
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr class="order-table-header-row">
      @if (showCheckbox && selectionMode === 'multiple') {
        <th>
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      }
      @if (showCheckbox && selectionMode === 'single') {
        <th>
      </th>
      }
      @for (columnHeaderItemField of getOrderedColumnSeenArray(); track columnHeaderItemField) {
        <th
          [pSortableColumn]="orderTableHeaderObj[columnHeaderItemField['field']]['isSortable'] ? orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName'] : ''"
          (click)="orderTableHeaderObj[columnHeaderItemField['field']]['isSortable'] ? isSortedBtnClicked=true : isSortedBtnClicked=false">
          @if (modifiedData(orderTableHeaderObj[columnHeaderItemField['field']]['groupRoutePlanArray'])) {
            <div></div>
          }
          <div class="order-table-column-header-item" tabindex="0">
          <div class="order-table-column-name-text">{{
            orderTableHeaderObj[columnHeaderItemField['field']]['columnDisplayName'] | translate
            }}<br class="iconAlignForMobile">
            @if (orderTableHeaderObj[columnHeaderItemField['field']]['isSortable']) {
              <p-sortIcon
                [field]="orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName']"></p-sortIcon>
            }
            @if (orderTableHeaderObj[columnHeaderItemField['field']]['isInlineEditable']) {
              <span>
                <button class="p-button-rounded p-button-text" icon="pi pi-pencil" pButton pRipple type="button"
                        [pTooltip]="orderTableHeaderObj[columnHeaderItemField['field']]['iconPlaceholder']"
                        tooltipPosition="top"></button>
              </span>
            }
            @if (orderTableHeaderObj[columnHeaderItemField['field']]['isFilterable'] && (orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuType'] === 'singleSelectFromOptions')) {
              <p-columnFilter
                [field]="orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName']"
                matchMode="singleSelectFromOptions" display="menu" [showMatchModes]="false" [showApplyButton]="false"
                [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="header">
                  <div class="px-3 pt-3 pb-0">
                    <span class="font-bold"></span>
                  </div>
                </ng-template>
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown [options]="orderTableHeaderObj[columnHeaderItemField['field']]['availableOptionsArray']"
                              [ngModel]="value" optionLabel="name" [virtualScroll]="true" [virtualScrollItemSize]="35"
                              [filter]="orderTableHeaderObj[columnHeaderItemField['field']]['isSearchAllowed'] ?? false"
                              placeholder="{{orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuHeaderText'] | translate}}"
                              (onChange)="filter($event.value); checkForWhichColumnFilterApplied($event.value, orderTableHeaderObj[columnHeaderItemField['field']])"></p-dropdown>
                </ng-template>
              </p-columnFilter>
            }
            @if (orderTableHeaderObj[columnHeaderItemField['field']]['isFilterable'] && (orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuType'] === 'multiSelectFromOptions')) {
              <p-columnFilter
                [field]="orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName']"
                matchMode="multiSelectFromOptions" display="menu" [showMatchModes]="false" [showApplyButton]="false"
                [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="header">
                  <div class="px-3 pt-3 pb-0">
                    <span class="font-bold"></span>
                  </div>
                </ng-template>
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect [ngModel]="value" [virtualScroll]="true" [virtualScrollItemSize]="35"
                                 (onChange)="filter($event.value); checkForWhichColumnFilterApplied($event.value, orderTableHeaderObj[columnHeaderItemField['field']])"
                                 [options]="orderTableHeaderObj[columnHeaderItemField['field']]['availableOptionsArray']"
                                 placeholder="{{orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuHeaderText'] | translate}}"
                                 optionLabel="name" display="chip">
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            }
            @if (orderTableHeaderObj[columnHeaderItemField['field']]['isFilterable'] && (orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuType'] === 'groupedMultiSelectFromOptions')) {
              <p-columnFilter
                [field]="orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName']"
                matchMode="groupedMultiSelectFromOptions" display="menu" [showMatchModes]="false"
                [showApplyButton]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="header">
                  <div class="px-3 pt-3 pb-0">
                    <span class="font-bold"></span>
                  </div>
                </ng-template>
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect [virtualScroll]="true" [virtualScrollItemSize]="35"
                    [options]="orderTableHeaderObj[columnHeaderItemField['field']]['availableOptionsArray']"
                    [group]="true"
                    [ngModel]="value"
                    displaySelectedLabel="false"
                    optionLabel="name"
                    placeholder="{{orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuHeaderText'] | translate}}"
                    scrollHeight="250px"
                    (onChange)="filter($event.value); checkForWhichColumnFilterApplied($event.value, orderTableHeaderObj[columnHeaderItemField['field']])"
                    display="chip">
                    <ng-template let-group pTemplate="group">
                      <div class="flex align-items-center">
                        <span>{{ group.name }}</span>
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            }
              @if (orderTableHeaderObj[columnHeaderItemField['field']]['isFilterable'] &&
              (orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuType'] === 'dateRange')) {
              <p-columnFilter [field]="orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName']" type="date"
                matchMode="dateRange" display="menu" [showMatchModes]="false" [showClearButton]="false" [showApplyButton]="false"
                [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="header">
                  <div class="px-3 pt-3 pb-0">
                    <span class="font-bold">Date Range</span>
                  </div>
                </ng-template>
                <!--p-calendar   (onInput)="setup(calendar.value, orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName'])" -->
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-calendar #calendar [ngModel]="value"
                    (onSelect)="filter(calendar.value); checkForWhichColumnFilterApplied(calendar.value, orderTableHeaderObj[columnHeaderItemField['field']])"
                    styleClass="p-column-filter" placeholder="Start Date and End Date" selectionMode="range" inputId="range"
                    [readonlyInput]="false" dateFormat="dd-mm-yy"></p-calendar>
                </ng-template>
              </p-columnFilter>
              }
              @if (orderTableHeaderObj[columnHeaderItemField['field']]['isFilterable'] &&
                orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuType'] === 'treeSelectFromOptions') {
                <p-columnFilter [field]="orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName']"
          [showAddButton]="false"
          [showApplyButton]="false"
          [showMatchModes]="false"
          [showOperator]="false"
          display="menu"
          matchMode="treeSelectFromOptions"
                >
                  <ng-template pTemplate="header">
                    <div class="px-3 pt-3 pb-0">
                      <span class="font-bold"></span>
                    </div>
                  </ng-template>
                  <ng-template
                    let-filter="filterCallback"
                    let-value
                    pTemplate="filter">
                    <p-treeSelect
                      #Multiselects
                      [options]="updatedData"
                      display="chip"
                      [metaKeySelection]="true"
                      selectionMode="checkbox"
                      [filter]="true"
                      [(ngModel)]="selectedRoutePlans"
                      (ngModelChange)="filter(optionsChange($event))"
                      [placeholder]="orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuHeaderText'] | translate">
                    </p-treeSelect>
                  </ng-template>
                </p-columnFilter>
              }
          </div>
        </div>
      </th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr
      [ngClass]="{
        'row-highlight' : (rowData['cardClicked'] || rowData['isAssetSelected']), 
        'row-disabled'  : rowData['disableRow'],
        'selected-row-highlight' : rowData['_id'] === (selectedRowId$ | async),
        'row-blurred'   : rowData['archived'], 
         }"
      [pTooltip]="(rowData['disableRow'] ? rowData['disableRowTooltipMessage'] : '')">
      @if (showCheckbox && selectionMode === 'multiple') {
        <td>
          @if (!rowData['isNotClickable']) {
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          }
      </td>
      }
      @if (showCheckbox && selectionMode === 'single') {
        <td>
          @if (!rowData['isNotClickable']) {
            <p-tableRadioButton [value]="(!(rowData['disableRow']) ? rowData  : null)"
                                [disabled]="rowData['disableRow']"></p-tableRadioButton>
          }
      </td>
      }
      @for (columnHeaderItemField of getOrderedColumnSeenArray(); track columnHeaderItemField) {
        <td pEditableColumn class="order-table-cell"
            [ngClass]="{'description-column' : orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName'] === 'description'}"
            [pTooltip]="(columnHeaderItemField['field']==='parcelCharge' && !rowData['parcelCharge']) && 'Category Parcel Charges are Applied'">
          @if (orderTableHeaderObj[columnHeaderItemField['field']]['isImg'] && !orderTableHeaderObj[columnHeaderItemField['field']]['isContentArray']) {
            <div class="image-container"
            >
              @if (rowData[columnHeaderItemField['field']]) {
                <img [src]="rowData[columnHeaderItemField['field']]"
                     onerror="src='assets/image-placeholder.png'" [alt]="" height="55"
                     class="shadow-4 table-data-image"/>
              }
            </div>
          }
          @if (
            !orderTableHeaderObj[columnHeaderItemField['field']]['isContentMultiple'] && 
            !orderTableHeaderObj[columnHeaderItemField['field']]['isContentDays'] && 
            !orderTableHeaderObj[columnHeaderItemField['field']]['isContentRelative'] &&
            !orderTableHeaderObj[columnHeaderItemField['field']]['isContentArray'] && 
            !orderTableHeaderObj[columnHeaderItemField['field']]['showIconList'] && 
            !orderTableHeaderObj[columnHeaderItemField['field']]['isSingleAction']&&
            !orderTableHeaderObj[columnHeaderItemField['field']]['isContentHeaderSubheader']) {
            <div class="order-status-label"
            >
              <!-- <div *ngIf="columnHeaderItemField['field'] === 'orderTime' || columnHeaderItemField['field'] === 'serviceTime'; else displayTextData">
              {{getTimeStamp(rowData[columnHeaderItemField['field']])}}
            </div> -->
          <!--  <ng-template #displayTextData> -->
              @if (!orderTableHeaderObj[columnHeaderItemField['field']]['isImg'] && orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuType'] !== 'anchor' && !orderTableHeaderObj[columnHeaderItemField['field']]['isRedirectLink']) {
                <div
                  id="{{orderTableHeaderObj[columnHeaderItemField['field']]['isClickable'] ? 'row-clickable' : 'row-not-clickable'}}"
                  [ngClass]="{
                    'row-clickable-button': orderTableHeaderObj[columnHeaderItemField['field']]['isClickable'],
                    'row-tag-val-container':true
                    }"
                  (click)="orderTableHeaderObj[columnHeaderItemField['field']]['isClickable'] ? clickableDataIsClicked(rowData) : ''"
                  data-table-data-type="{{columnHeaderItemField['field']}}">
  
                  {{
                    rowData[columnHeaderItemField['field']] ?
                      orderTableHeaderObj[columnHeaderItemField['field']]['isNamingConventionReq'] ?
              (rowData[columnHeaderItemField['field']] | namingConvention)
              :
              columnHeaderItemField['field'] === 'amount' ? getFixedAmount(rowData[columnHeaderItemField['field']]) : rowData[columnHeaderItemField['field']]
                      : '-'
                  }}
                  @if(rowData['archived'] && orderTableHeaderObj[columnHeaderItemField['field']]['isClickable']===true){
                    <span class="row-tag">Archived</span>
                  }
                
                </div>
              }
              @if (orderTableHeaderObj[columnHeaderItemField['field']]['isRedirectLink']) {
                <span>
              @if (rowData['customerTrackingLink']) {
                <a href="{{rowData['customerTrackingLink']}}" target="_blank">
                  <!-- {{rowData[columnHeaderItemField['field']] ?
                  orderTableHeaderObj[columnHeaderItemField['field']]['labelForLink'] : '-' }} -->
                  @if (orderTableHeaderObj[columnHeaderItemField['field']]['imageSource']) {
                    <i [ngClass]="orderTableHeaderObj[columnHeaderItemField['field']]['imageSource']"></i>
                  }</a>
              }
                  @if (!rowData['customerTrackingLink']) {
                    <span>-</span>
                  }
              </span>
              }
              @if (orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuType'] === 'anchor') {
                <a href="#"
                   onclick="return false;" class="anchor remove-anchor">
                  @if (rowData['showRemoveBtn']) {
                    <span
                      (click)="removeData($event, rowData)">{{ orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuHeaderText'] }}</span>
                  }
                </a>
              }
            </div>
          }
          @if (orderTableHeaderObj[columnHeaderItemField['field']]['orderStatusIsUpdateable'] || (orderTableHeaderObj[columnHeaderItemField['field']]['isContentArray'] && !orderTableHeaderObj[columnHeaderItemField['field']]['isChip'] && !orderTableHeaderObj[columnHeaderItemField['field']]['isToggle'] && !orderTableHeaderObj[columnHeaderItemField['field']]['isInlineEditable'])) {
            <div class="order-details-container"
            >
              <!-- ORDER STATUS -->
              <div class="order-items-list-container">
                @for (item of rowData[columnHeaderItemField['field']]; track item) {
                  <li class="order-items-details"
                      style="position: relative;">
                    @if (orderTableHeaderObj[columnHeaderItemField['field']]['orderStatusIsUpdateable']) {
                      <span
                        [ngStyle]="{'cursor':'pointer'}"
                        (click)="setOrderStatusUpdateModal(rowData['pickup']?.status, rowData['orderId'], rowData['_id'], rowData['paymentStatus'].label)"
                      >
                      @if (rowData['pickup']?.status) {
                        <span class="label-badge status"
                              [ngClass]="'label-badge status-' + getOrderStatusClass(rowData['pickup']?.status)">
                          {{ rowData['pickup']?.status }}
                          <!-- {{orderTableHeaderObj[columnHeaderItemField['field']]['isNamingConventionReq'] ? (item | namingConvention) : item}} -->
                        </span>
                      }
                    </span>
                    } @else {
                      <span>{{ orderTableHeaderObj[columnHeaderItemField['field']]['isNamingConventionReq'] ? (item | namingConvention) : item }}</span>
                    }
                  </li>
                }
              </div>
              @if (orderTableHeaderObj[columnHeaderItemField['field']]['showSubtitle'] === true) {
                <div class="dataSubtitle">
                  {{ rowData[columnHeaderItemField['field'] + 'Subtitle'] }}
                </div>
              }
              @if (orderTableHeaderObj[columnHeaderItemField['field']]['isChip'] && !orderTableHeaderObj[columnHeaderItemField['field']]['isLabel']) {
                <div
                ></div>
              }
            </div>
          }
          @if (orderTableHeaderObj[columnHeaderItemField['field']]['isContentArray'] && orderTableHeaderObj[columnHeaderItemField['field']]['isChip'] === true) {
            <div class="groups-container"
            >
              @if (orderTableHeaderObj[columnHeaderItemField['field']]['isChip'] === true && !orderTableHeaderObj[columnHeaderItemField['field']]['isColorLabel'] === true) {
                <span
                >
                @for (badge of rowData[columnHeaderItemField['field']]; track badge) {
                  <span
                    style="margin-right: 1rem; margin-top: 1rem;">
                    <p-chip [label]="badge['label']" styleClass="mb-2 ml-1 custom-chip" removable="true"
                            (onRemove)="sendTableChipRemoved(badge['label'], rowData, badge['_id'] ,orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName'])"
                            [style]="{'background-color': badge['color'], 'color': badge['color'] ? 'white' : '' }"></p-chip>
                  </span>
                }
              </span>
              }
              <!-- color coded label -->
              @if (orderTableHeaderObj[columnHeaderItemField['field']]['isChip'] === true && orderTableHeaderObj[columnHeaderItemField['field']]['isColorLabel'] === true) {
                <span [ngStyle]="{'cursor':'pointer'}"
                >
                <span class="label-badge status"
                      [ngClass]="'label-badge status-' + rowData[columnHeaderItemField['field']]?.status"
                      (click)="setPaymentStatusModal(rowData[columnHeaderItemField['field']]['label'], rowData['orderId'], rowData['_id'], rowData['amount'], rowData['accountId'], rowData['paymentStatus'])">
                  {{ rowData[columnHeaderItemField['field']]?.label }}
                </span>
              </span>
              }
            </div>
          }
          @if (orderTableHeaderObj[columnHeaderItemField['field']]['isToggle'] === true && orderTableHeaderObj[columnHeaderItemField['field']]['isContentArray']) {
            <div class="toggle-container"
            >
              <p-inputSwitch [(ngModel)]="rowData[columnHeaderItemField['field']]"
                             (onChange)="inputSwitchChange($event, rowData )"
                             [inputId]="rowData['_id']"></p-inputSwitch>
            </div>
          }
          @if (
          orderTableHeaderObj[columnHeaderItemField['field']]['isContentDays']) {
            @if(rowData[columnHeaderItemField['field']]?.length){
              <app-days-view-in-table [selectedDays]="rowData[columnHeaderItemField['field']]"/>
            }@else{
              -
            }
          }
          @if (
          orderTableHeaderObj[columnHeaderItemField['field']]['isContentMultiple']) {
            @if(rowData[columnHeaderItemField['field']]){
              <app-multiple-values-in-table [content]="rowData[columnHeaderItemField['field']]"/>
            }@else{
              -
            }
          }
          @if (
          orderTableHeaderObj[columnHeaderItemField['field']]['isContentRelative']) {
            <app-relative-data-view-in-table [value]="rowData[columnHeaderItemField['field']]"/>
          }
          @if (
          orderTableHeaderObj[columnHeaderItemField['field']]['isContentHeaderSubheader']) {
              <app-header-subheader-view-in-table 
              [color]="(rowData[columnHeaderItemField['field']]?.header | statusToColorPipe)"
              [header]="rowData[columnHeaderItemField['field']]?.header"
              [subheader]="rowData[columnHeaderItemField['field']]?.subheader"/>
          }
          @if (orderTableHeaderObj[columnHeaderItemField['field']]['isInlineEditable'] === true) {
            <p-cellEditor
            >
              <ng-template pTemplate="input">
                <input class="editInput" pInputText type="number"
                       [min]="orderTableHeaderObj[columnHeaderItemField['field']]['minValue']"
                       [(ngModel)]="rowData[columnHeaderItemField['field']]"
                       (ngModelChange)="cellEditorEvent(rowData, rowData[columnHeaderItemField['field']],columnHeaderItemField['field'])"
                       [disabled]="columnHeaderItemField['field']==='parcelCharge' && !rowData[columnHeaderItemField['field']]">
              </ng-template>
              <ng-template pTemplate="output">
              <span class="cursorPointer">
                {{ rowData[columnHeaderItemField['field']] ? (rowData[columnHeaderItemField['field']]) : '-' }}
              </span>
              </ng-template>
            </p-cellEditor>
          }
          @if (orderTableHeaderObj[columnHeaderItemField['field']]['showIconList'] === true) {
            <div>
              @if (rowData[columnHeaderItemField['field']]?.label !== 'N/A') {
                <span>
                <p-confirmPopup appendTo="body" key={{rowData?._id}}></p-confirmPopup>
                <button (click)="openActionMenu($event,rowData)" class="action-btn" pRipple
                        type="button" [disabled]="!rowData?.actionMethods?.length"
                        tooltipEvent="hover" pTooltip="More actions" tooltipPosition="left"
                        (click)="menu.toggle($event);domEvent=$event" pButton icon="pi pi-ellipsis-v"></button>
                <p-menu appendTo="body" #menu [popup]="true" [model]="actionMenuItems"></p-menu>
              </span>
              }
            </div>
          }
          @if (orderTableHeaderObj[columnHeaderItemField['field']]['isSingleAction'] === true) {
            <div>
              @if (rowData?.['isSingleActionDisable'] !== true) {
                <i class="cursorPointer"
                   (click)="openSingleAction(rowData,orderTableHeaderObj[columnHeaderItemField['field']])"
                   [pTooltip]="orderTableHeaderObj[columnHeaderItemField['field']]['label']"
                   tooltipPosition="bottom" [class]="orderTableHeaderObj[columnHeaderItemField['field']]['icon']"></i>
              }
            </div>
          }
      </td>
      }
    </tr>
  </ng-template>
</p-table>

<p-confirmPopup key="prime-table-popup"></p-confirmPopup>

<ng-template #getNewOrdersOnRefreshUi>
  @if (autoRefreshBtnConfig?.showRefreshUI) {
    <span style="position: relative;" class="refresh-icon FR d-flex">
      @if (!autoRefreshBtnConfig?.checked) {
        <i
          class="pi pi-refresh cursorPointer"
          id="refreshButton" (click)="emittFetchNewOrdersAction()"
          [pTooltip]="(autoRefreshBtnConfig?.newOrdersCount > 0) ? 'Click to Refresh' : ''"
          tooltipPosition="bottom">
        </i>
      }
      @if (!autoRefreshBtnConfig?.checked) {
        <p-badge [value]="autoRefreshBtnConfig?.newOrdersCount"
                 [ngClass]="{'blink-animate': autoRefreshBtnConfig?.newOrdersCount > 0}"
                 class="mr-4 auto-refresh-badge">
        </p-badge>
      }
      <p-toggleButton class="auto-refresh-btn mr-3" (onChange)="autoRefreshToggle($event)"
                      [(ngModel)]="autoRefreshBtnConfig.checked"
                      onLabel="A" offLabel="A" [onIcon]="'pi pi-refresh'" offIcon="pi pi-refresh"
                      [pTooltip]="autoRefreshBtnConfig?.checked ? 'Disable Auto Refresh' : 'Enable Auto Refresh'"
                      tooltipPosition="bottom">
      </p-toggleButton>
    </span>
  }
</ng-template>
